import React from 'react';

export default function Footer() {
    let this_date = new Date();
    let this_year = this_date.getFullYear();

    return (
        <footer>
            <p>©{this_year} enyoc llc, dba forg3d.net</p>
        </footer>
    )
}