import React from 'react';
import Button from './button';

export default function Productbox(props) {
    const on_sale = props.productsale;
    const price_range = props.productrange;
    /*if (on_sale) {
        return (
            <div className="product">
                <a id={props.productID} href={props.productpage}>
                    <img src={props.productimage} alt={props.productname} />
                    <h4>{props.productname}</h4>
                    <div className="productprice">
                        <p className="prevprice">${props.regprice}</p>
                        <p className="currentprice">${props.saleprice}</p>
                    </div>
                    <Button buttonID="gamebutton" buttonsource={props.buttonlink} buttonname="Buy Now" />
                </a>
            </div>
        )
    } else if (price_range) {
        return (
            <div className="product">
                    <a id={props.productID} href={props.productpage}>
                        <img src={props.productimage} alt={props.productname} />
                        <h4>{props.productname}</h4>
                        <div className="productprice">
                            <p className="currentprice">${props.saleprice} - ${props.regprice}</p>
                        </div>
                        <Button buttonID="gamebutton" buttonsource={props.buttonlink} buttonname="Buy Now" />
                    </a>
            </div>
        )
    } else { */
        return (
            <div className="product">
                <a id={props.productID} href={props.productpage}>
                    <img src={props.productimage} alt={props.productname} />
                    <h4>{props.productname}</h4>
                    <Button buttonID="gamebutton" buttonsource={props.buttonlink} buttonname="Shop Now" />
                </a>
            </div>
        )
    //}
}