import React from 'react';
import Button from '../components/button.js';
import productlist from '../products.json';
import Productbox from '../components/productbox.js';
import Servicebox from '../components/servicebox.js';

function Home() {

    let test = [];
    for (let product of productlist.products) {
      if (product.featured === true) {
        test.push(<Productbox productsale={product.sale} productrange={product.range} productpage={product.url} productimage={require(`../images/${product.code_name}.jpeg`)} productname={product.product_name} regprice={product.reg_price.toFixed(2)} saleprice={product.sale_price.toFixed(2)} buttonlink={product.url} />)
      }
    }

  return (
    <div id="home">
      <header id="banner">
        <div id="titleaction">
          <h1>Forg3d</h1>
          <h2>3d print farm, product design services, and custom solutions for RV and Jeep owners</h2>
          <Button buttonID="actionbutton" buttonsource="https://enyoc.square.site/" buttonname="Shop Now!" />
        </div>
        <ul id="imagecarousel">
          <li>
            <span></span>
          </li>
          <li>
            <span></span>
          </li>
          <li>
            <span></span>
          </li>
          <li>
            <span></span>
          </li>
        </ul>
      </header>
      <section id="featureditems">
        <h2 className="subheader">Featured Items</h2>
        {test}
      </section>
      <section id="services">
        <h2 className="subheader">Our Services</h2>
        <Servicebox serviceimage={require("../images/forg3d-logo.png")} servicename="Custom Products" description="Value adding products for RV and Jeep owners." />
        <Servicebox serviceimage={require("../images/forg3d-logo.png")} servicename="3D Design Services" description="Bring your product design or invention to life with our 3D modeling services. Reverse engineering and 3D scanning services available." />
        <Servicebox serviceimage={require("../images/forg3d-logo.png")} servicename="Small Scale Manufacturing" description="No job too small! Whether you need one, or 1000, our knowledge of additive manufacturing and our 3D print farm stand ready to meet your manufacturing needs." />
      </section>
      <section id="fbdownload">

        <div id="leftbutton">
          <Button buttonID="downloadbutton" buttonsource="https://1drv.ms/u/s!Am2gHRuuKaLlj-FUACLjo739p_KR-w?e=j677bI" buttonname="Downloads" />
        </div>
        <div id="middlebutton">
            <Button buttonID="gamebutton" buttonsource="/pennydrop" buttonname="Game Instructions" />
        </div>
        <div id="rightbutton">
          <Button buttonID="facebookbutton" buttonsource="https://www.facebook.com/groups/tiffinhacks/" buttonname="Facebook Group - Tiffin RV Mods &amp; Hacks" />
        </div>
      </section>
      <section id="aboutus">
        <div id="leftside">
          <h3>About Us</h3>
          <p>A passion for design and mechanical properties come together to meet your design and product needs.</p>
        </div>
        <div id="rightside">
          <img src={require("../images/forg3d-logo.png")} />
        </div>
      </section>
      <section id="contact">
        <h2 className="subheader">Talk to Us</h2>
        <p>Have any questions? We are always open to talk about your business, new projects, creative opportunities and how we can help you.</p>
        <Button buttonID="contactbutton" buttonsource="mailto:john@enyoc.com" buttonname="Get in Touch" />
      </section>
    </div>
  );
}

export default Home;